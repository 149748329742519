import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We are all different,`}<br parentName="p"></br>{`
`}{`We are not transparent.`}<br parentName="p"></br>{`
`}{`Our minds are not the same,`}<br parentName="p"></br>{`
`}{`And there is nobody to blame.`}</p>
    <p>{`Diversity wasn’t meant to harm us,`}<br parentName="p"></br>{`
`}{`Or make us stand inside the bus.`}<br parentName="p"></br>{`
`}{`If a person was blind,`}<br parentName="p"></br>{`
`}{`They wouldn’t need colors, only their mind.`}</p>
    <p>{`The population is big,`}<br parentName="p"></br>{`
`}{`And every time we act like a pig,`}<br parentName="p"></br>{`
`}{`It will shrink,`}<br parentName="p"></br>{`
`}{`Until our hearts will sink.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      